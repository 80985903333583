import React from 'react';

import { Link } from 'gatsby';

import style from '../assets/css/main.module.css';
import pageStyle from '../assets/css/page.module.css';
import imgHero from '../assets/img/heroes/jobs.jpg';
import Footer from '../components/footer';
import Hamburger from '../components/hamburger';
import HeroPage from '../components/hero.page';
import Menu from '../components/menu';
import SEO from '../components/seo';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Jobs | Agence Digitale | MDS Digital Agency"
      description="Découvrez nos dernières offres d'emplois"
      keywords="vie privée"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgHero} />

    <article className={pageStyle.article} style={{ paddingTop: '50px', paddingBottom: '50px' }}>
      {/*<><p>Envie de rejoindre une super équipe ? On recherche un.e...</p>
      <ul>
        <li>
          <Link to="/jobs/community_manager/">Community Manager</Link>
        </li>
      </ul>
      <p>
        Sois pas timide et impressionne-nous ! Envoie ton CV à{' '}
        <a href="mailto:info@mds-digitalagency.be">info@mds-digitalagency.be</a>.
      </p></>*/
      }
      <h2 className={pageStyle.header_uppercase}>Postes à pourvoir</h2>
      <p>Aucun poste à pourvoir pour le moment !</p>
    </article>
    <Footer />
  </div>
);
